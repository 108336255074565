const VueInterval = {
  ///* <vue2>
  usable: true,
  // </vue2> */
  install: (app, options) => {
    const setter = app.config?.globalProperties ?? app.prototype
    setter.$interval = function tmp(callback, interval = 1000) {
      const curInterval = setInterval(() => {
        // для vue3 надо искать другой стособ
        if (this.$el.__vue__) {
          callback.apply(this)
        } else if (curInterval) {
          clearInterval(curInterval)
        }
      }, interval)
    }
  },
}

export default VueInterval
