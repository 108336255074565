const summary = {
  qaqc_condition_photo_delivery: {
    icon: 'delivery',
    value: 0,
  },
  qaqc_condition_photo_apartment: {
    icon: 'apartment',
    value: 0,
  },
  qaqc_condition_photo_fridge: {
    icon: 'fridge',
    value: 0,
  },
  qaqc_condition_photo_freezer: {
    icon: 'freezer',
    value: 0,
  },
  qaqc_condition_photo_copay: {
    icon: 'copay',
    value: 0,
  },
  qaqc_condition_date_delivery: {
    icon: 'date',
    value: 0,
  },
  qaqc_condition_geofence_view: {
    icon: 'geofence',
    value: 0,
  },
  qaqc_condition_photo_package: {
    icon: 'package',
    value: 0,
  },
  qaqc_condition_photo_id: {
    icon: 'id',
    value: 0,
  },
  qaqc_condition_signature: {
    icon: 'signature',
    value: 0,
  },
  qaqc_condition_delivery_method: {
    icon: 'method',
    value: 0,
  },
  qaqc_condition_recipient_name: {
    icon: 'name',
    value: 0,
  },
  qaqc_condition_special_instructions: {
    icon: 'instructions',
    value: 0,
  },
  qaqc_condition_attempt_call_pharmacy: {
    icon: 'attempt_call_pharmacy',
    value: 0,
  },
  qaqc_condition_attempt_call_recipient: {
    icon: 'attempt_call_recipient',
    value: 0,
  },
  qaqc_condition_attempt_dispatch_note: {
    icon: 'attempt_call_recipient',
    value: 0,
  },
  qaqc_condition_special_instructions_driver: {
    icon: 'instructions',
    value: 0,
  },
}
export default summary
