import { defineAsyncComponent } from 'vue'

export default {
  'site-section': defineAsyncComponent(() => import(/* webpackChunkName: "common" */ '@/common/site-section.vue')),
  'special-instructions': defineAsyncComponent(() => import(/* webpackChunkName: "new-order" */ '@/pages/Orders/New/elements/special-instructions.vue')),
  'station-instructions': defineAsyncComponent(() => import(/* webpackChunkName: "new-order" */ '@/pages/Orders/New/elements/station-instructions.vue')),
  'recipient-phone-call': defineAsyncComponent(() => import(/* webpackChunkName: "new-order" */ '@/pages/Orders/New/elements/recipient-phone-call.vue')),
  'recipient-phone-home': defineAsyncComponent(() => import(/* webpackChunkName: "new-order" */ '@/pages/Orders/New/elements/recipient-phone-home.vue')),
  'recipient-phone-work': defineAsyncComponent(() => import(/* webpackChunkName: "new-order" */ '@/pages/Orders/New/elements/recipient-phone-work.vue')),
  'recipient-name': defineAsyncComponent(() => import(/* webpackChunkName: "new-order" */ '@/pages/Orders/New/elements/recipient-name.vue')),
  'recipient-address': defineAsyncComponent(() => import(/* webpackChunkName: "new-order" */ '@/pages/Orders/New/elements/recipient-address.vue')),
  'approve-method': defineAsyncComponent(() => import(/* webpackChunkName: "new-order" */ '@/pages/Orders/New/elements/approve-method.vue')),
  'documents-to-sign': defineAsyncComponent(() => import(/* webpackChunkName: "new-order" */ '@/pages/Orders/New/elements/documents-to-sign.vue')),
  'subtypes-switches': defineAsyncComponent(() => import(/* webpackChunkName: "new-order" */ '@/pages/Orders/New/elements/subtypes-switches.vue')),
  'delivery-type': defineAsyncComponent(() => import(/* webpackChunkName: "new-order" */ '@/pages/Orders/New/elements/delivery-type.vue')),
  'external-delivery-type': defineAsyncComponent(() => import(/* webpackChunkName: "new-order" */ '@/pages/Orders/New/elements/external-delivery-type.vue')),
  'insurance-rate': defineAsyncComponent(() => import(/* webpackChunkName: "new-order" */ '@/pages/Orders/New/elements/insurance-rate.vue')),
  'custom-fields': defineAsyncComponent(() => import(/* webpackChunkName: "new-order" */ '@/pages/Orders/New/elements/custom-fields.vue')),
  'operator-initials': defineAsyncComponent(() => import(/* webpackChunkName: "new-order" */ '@/pages/Orders/New/elements/operator-initials.vue')),
  'operator-name': defineAsyncComponent(() => import(/* webpackChunkName: "new-order" */ '@/pages/Orders/New/elements/operator-name.vue')),
  'recipient-emails': defineAsyncComponent(() => import(/* webpackChunkName: "new-order" */ '@/pages/Orders/New/elements/recipient-emails.vue')),
  'recipient-lang': defineAsyncComponent(() => import(/* webpackChunkName: "new-order" */ '@/pages/Orders/New/elements/recipient-lang.vue')),
  'upon-arrival': defineAsyncComponent(() => import(/* webpackChunkName: "new-order" */ '@/pages/Orders/New/elements/upon-arrival.vue')),
  'agree-optin': defineAsyncComponent(() => import(/* webpackChunkName: "new-order" */ '@/pages/Orders/New/elements/agree-optin.vue')),
  'email-to-own': defineAsyncComponent(() => import(/* webpackChunkName: "new-order" */ '@/pages/Orders/New/elements/email-to-own.vue')),
  'delivery-date': defineAsyncComponent(() => import(/* webpackChunkName: "new-order" */ '@/pages/Orders/New/elements/delivery-date.vue')),
  'delivery-interval-preset': defineAsyncComponent(() => import(/* webpackChunkName: "new-order" */ '@/pages/Orders/New/elements/delivery-interval-preset.vue')),
  'delivery-interval-manual': defineAsyncComponent(() => import(/* webpackChunkName: "new-order" */ '@/pages/Orders/New/elements/delivery-interval-manual.vue')),
  'package-size': defineAsyncComponent(() => import(/* webpackChunkName: "new-order" */ '@/pages/Orders/New/elements/package-size.vue')),
  'items-count': defineAsyncComponent(() => import(/* webpackChunkName: "new-order" */ '@/pages/Orders/New/elements/items-count.vue')),
  'business-selector': defineAsyncComponent(() => import(/* webpackChunkName: "new-order" */ '@/pages/Orders/New/elements/business-selector.vue')),
  'pickup-date': defineAsyncComponent(() => import(/* webpackChunkName: "new-order" */ '@/pages/Orders/New/elements/pickup-date.vue')),
  'pickup-time-min': defineAsyncComponent(() => import(/* webpackChunkName: "new-order" */ '@/pages/Orders/New/elements/pickup-time-min.vue')),
  'pickup-time-max': defineAsyncComponent(() => import(/* webpackChunkName: "new-order" */ '@/pages/Orders/New/elements/pickup-time-max.vue')),
  'copay-rate': defineAsyncComponent(() => import(/* webpackChunkName: "new-order" */ '@/pages/Orders/New/elements/copay-rate.vue')),
  'copay-action': defineAsyncComponent(() => import(/* webpackChunkName: "new-order" */ '@/pages/Orders/New/elements/copay-action.vue')),
  'copay-prepaid': defineAsyncComponent(() => import(/* webpackChunkName: "new-order" */ '@/pages/Orders/New/elements/copay-prepaid.vue')),
  'rx-list': defineAsyncComponent(() => import(/* webpackChunkName: "new-order" */ '@/pages/Orders/New/elements/rx-list.vue')),
  'order-details-mini': defineAsyncComponent(() => import(/* webpackChunkName: "new-order" */ '@/pages/Orders/New/elements/order-details-mini.vue')),
}
