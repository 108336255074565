///* <vue2>
import VueI18n from 'vue-i18n'
// </vue2> */
/* <vue3>
import { createI18n } from 'vue-i18n'
// </vue3> */

import en from '@/i18n/en'
import r4rEn from '@/i18n/r4r-en'
import { isRx4Route } from '@/helper.js'

const getFallbackLocales = () => {
  const fallbackLocale = {
    // 'es': ['en-GB'],
    default: ['en'],
  }

  if (isRx4Route) {
    fallbackLocale.default.unshift('r4r-en')
  }

  return fallbackLocale
}

const getMessages = () => {
  const messages = {
    en,
  }

  if (isRx4Route) {
    messages['r4r-en'] = r4rEn
  }

  return messages
}

///* <vue2>
const createI18n = o => new VueI18n(o)
// </vue2> */

const i18n = () => createI18n({
  locale: isRx4Route ? 'r4r-en' : 'en',
  fallbackLocale: getFallbackLocales(),
  messages: getMessages(),
  // silentTranslationWarn: true,
})

///* <vue2>
i18n.usable = VueI18n
i18n.module = 'i18n'
// </vue2> */

export default i18n
