import { mapGetters } from 'vuex'
import axios from 'axios'
import _ from 'lodash'
import { setItem } from '@/helper.js'
import summary from '@/pages/Qaqc/RejectedQueue/icons'

export default {
  props: {
    order: {
      type: Object,
      required: true,
    },
  },

  computed: {
    ...mapGetters(['currentUser', 'facilities']),
    rejectedQaqc() {
      if (this.order?.activeNoteId) {
        const rejectedQueueAnalyze = {}
        const activeNote = _.filter(this.order?.notes?.driver, note => note.uid === this?.order?.activeNoteId)
        _.map(...activeNote, (qaqcCondition, key) => {
          if (Object.keys(summary).includes(key) && qaqcCondition === 'no') {
            const trimmedKey = String(key).replace(/^qaqc_condition_(special_|photo_|delivery_)?/, '')
            rejectedQueueAnalyze[trimmedKey] = true
          }
        })
        return rejectedQueueAnalyze
      }
      return {}
    },
    currentClient() {
      if (this.clientId !== this.currentUser?.uid) { return this.$store.getters.currentClient(this.clientId) }
      return this.currentUser
    },
    currentFacility() {
      return this.facilities[this.currentUser.facility]
    },
    orderId() {
      return this.order?.uid
    },
    isExternal() {
      return this.order?.deliver?.carrier !== 'RX2GO'
    },
    clientId() {
      return this.order?.clientid
    },
    canManage() {
      return !['Delivered', 'Rejected', 'Returned', 'Back to pharmacy'].includes(this.order.status)
    },
    hasPhone() {
      return Object.values(this.phones).length > 0
    },
    phones() {
      const phones = {}
      _.map(this.order.phones, (n, k) => {
        if (Array.isArray(n)) {
          _.map(n, (m, i) => {
            if (m) phones[`${k}-${i}`] = m
          })
        } else if (n) phones[k] = n
      })
      return phones
    },
    availableTypes() {
      if (this.orderId && ![9, 20, 26, 22, 25, 27, 19].includes(this.order?.deliver?.type)) {
        return [
          {
            id: this.order?.deliver?.type,
            origin_title: this.order?.deliver?.type_name,
          },
        ]
      }
      return _.filter(this.currentClient?.orderTypes, el => [9, 20, 26, 22, 25, 27, 19].includes(el.id)) ?? []
    },
    availablePeriods() {
      return {
        22: ['9:00am-1:00pm', '1:00pm-5:00pm', '5:00pm-9:00pm'],
        27: ['9:00am-3:00pm', '3:00pm-9:00pm'],
      }
    },
    selectedType() {
      let cur
      _.map(this.availableTypes, type => {
        if (type.id === this.order.deliver.type) {
          cur = type
        }
      })
      return cur
    },
    enabledSubTypes() {
      const list = {}
      if (this.selectedType) {
        _.map(this.availableSubTypes, (cur, key) => {
          const type = key.replace(/^subtype_/, '')
          if (this.selectedType[key] === 'yes'
            || (this.selectedType.id === 12 && key === 'subtype_fridge')
          ) {
            list[type] = cur
          }
          // We are allowing creation order from various sources and ignoring this setting.
          // else if (this.order.subtype[type] === 'yes') {
          //   this.order.subtype[type] = 'no'
          // }
        })
      }
      return list
    },
    availableSubTypes() {
      return this.$store.getters['Orders/New/subtypes']
    },
    isAllowEdit() {
      // TODO: Do not forget to remove this
      const restrictedUsersIds = [
        219236434,
        219236329,
        219236431,
        219236332,
        219236335,
        219236338,
        219236341,
        219236344,
        219236347,
        219236350,
        219236353,
        219236356,
        219236359,
        219236362,
        219236365,
        219236368,
        219236371,
        219236374,
        219236377,
        219236380,
        219236383,
        219236386,
        219236389,
        219236392,
        219236395,
        219236398,
        219236401,
        219236404,
        219236407,
        219236410,
        219236413,
        219236416,
        219236419,
        219236422,
        219236425,
        219236428,
        219236437,
        219236440,
        219236443,
        219236446,
        219236449,
        219236452,
        219236455,
        219236458,
      ]

      if (this.currentUser?.permission?.isRx4Route && restrictedUsersIds.includes(this.currentUser?.uid)) {
        return false
      }

      return (this.currentUser?.permission?.isAdmin || ['Ready to Print', 'Ready for Pickup', 'Draft'].includes(this.order.status) || this.currentUser.username === 'dketzler')
        && !this.currentUser?.permission?.isPharmacyStaffRO
    },
    orderNotPayingCopayActionTitle() {
      return this.order.not_paying_copay_action === 100
        ? this.order.not_paying_copay_action_title
        : 'What to do if patient not paying copay'
    },
  },
  methods: {
    getActiveNote() {

    },
    promptReason(title, message = null, options = { type: 'textarea' }) {
      return window.getPrompt(message || 'Specify the reason for editing the order:', {
        title,
        min: 5,
        ...options,
      }, this.currentFacility?.customize?.disable_order_edit_note === 'yes')
    },
    changeField(promptTitle, field, value, { skipSetItem = false, altField = null, orderPart = null } = {}) {
      console.log('this::: ', this)
      if (this.order.deliver.date) {
        console.log('date:::', this.order.deliver.date)
      }
      const resultAxios = null
      const promise = this.promptReason(promptTitle)
        .then(note => axios.post(
          '/ajax/PullOrders.php',
          { orderId: this.orderId, field, value, note, dateDeliver: this.order.deliver.date },
          { params: { action: 'updateClientFields' } },
        ))
        .then(response => {
          // Проверяем данные из ответа
          if (response.data.error === true && response.data.desc !== '') {
            alert(response.data.desc)
          }
          return response // Возвращаем ответ для дальнейшего использования
        })
      // console.log('Promise:::', promise)
      // if (promise.data.error === true && promise.data.desc !== '') {
      //   alert(promise.data.desc)
      // }
      if (skipSetItem) {
        return promise
      }

      return promise.then(() => {
        setItem(orderPart ? this.order[orderPart] : this.order, altField || field, value)
        this.refresh()
      })
    },

    getRate(feedback) {
      const rate = []
      if (feedback.rate_driver) {
        rate.push(feedback.rate_driver)
      }
      if (feedback.rate_dispatcher) {
        rate.push(feedback.rate_dispatcher)
      }
      if (feedback.rate_pharmacy) {
        rate.push(feedback.rate_pharmacy)
      }
      const formatter = new Intl.NumberFormat('en-US', {
        minimumFractionDigits: 1,
        maximumFractionDigits: 1,
      })

      const c = rate.reduce((partialSum, a) => partialSum + a, 0) / rate.length
      const x = formatter.format(c)

      return x % 1 === 0 ? c : x
    },
    refresh() {
      this.$mitt.emit('refresh-activity-history-table-order')
      this.$mitt.emit('refresh-order-status-history-table')
    },
    getTimeZone() {
      return this.facilities?.[this.order.facility.id]?.time_zone || 'America/New_York'
    },
    dateFormat(dateString, format = 'MM/DD/YYYY h:mm A') {
      return moment(dateString).format(format)
    },
    dateFormatX(unix, format = 'MM/DD/YYYY h:mm A') {
      return moment(unix * 1000).tz(this.getTimeZone()).format(format)
    },
    isBlocked(number) {
      let is = false
      _.map(this.order['phones-blocked'], e => {
        if (e.number === number) {
          is = e
        }
      })
      return is
    },
    isLandLine(number) {
      let is = false
      _.map(this.order['phones-landlines'], e => {
        if (e.number === number) {
          is = e
        }
      })
      return is
    },
  },
}
