///* <vue2>
import Vue from 'vue'

export const createApp = render => {
  const options = {
    render: h => h(render),
  }

  const model = {
    component(...args) {
      Vue.component(...args)
    },
    use(item, opts = {}) {
      if ('usable' in item) {
        if (typeof item.usable === 'boolean') {
          Vue.use(item, opts)
        } else {
          Vue.use(item.usable, opts)
        }
      }
      if ('module' in item) {
        if (typeof item === 'function') {
          options[item.module] = item()
        } else {
          options[item.module] = item
        }
      }
      return model
    },
    mount(el) {
      return new Vue({
        el,
        ...options,
      })
    },
  }
  return model
}

export default Vue
// </vue2> */
