///* <vue2>
import Vuetify from 'vuetify'

import 'vuetify/dist/vuetify.min.css'
import '@mdi/font/css/materialdesignicons.min.css'

const cur = {
  vuetify: null,
}
const vuetify = () => {
  cur.vuetify = new Vuetify({})
  return cur.vuetify
}

vuetify.usable = Vuetify
vuetify.module = 'vuetify'
// </vue2> */
/* <vue3>
import { createApp } from 'vue'

// Vuetify
import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'

const vuetify = createVuetify({
  components,
  directives
})
// </vue3> */

export default vuetify
export function useVytify() {
  return cur.vuetify
}
