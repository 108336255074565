import axios from 'axios'
import _ from 'lodash'
import { TYPE, POSITION } from 'vue-toastification'
import { mapGetters } from 'vuex'
import { defineAsyncComponent } from 'vue'
import Rules from '@/utils/Rules.js'
import { setItem } from '@/helper.js'
import { SourceGoogle } from '@/utils/Address.js'

const PopupStripePayment = defineAsyncComponent(() => import('@/pages/Orders/New/popup-stripe-payment'))

const defaultPhoneCustomize = {
  notification_settings: {
    auto_calls: true,
    live_calls: true,
    sms: true,
  },
}

const orderDefault = {
  uid: null,
  clientid: null,
  source: 'dashboard-rx2go',
  phones: {
    call: '',
    home: null,
    work: null,
  },
  phones_customize_data: {
    call: defaultPhoneCustomize,
    home: defaultPhoneCustomize,
    work: defaultPhoneCustomize,
  },
  items: 1,
  copay: '0.00',
  copay_in_documents: '0.00',
  subtype: {},
  recipient: {
    email_to_owner: 'no',
    todays_order: null,
    landLineNumber: null,
    lang: 'en',
    name: '',
    emails: [
      '',
    ],
    address: {
      address: '',
      source: SourceGoogle,
      state: '',
      city: '',
      zip: '',
      apt: '',
      lat: '',
      lng: '',
      place_id: '',
      formatted_address: '',
      adjusted: false,
    },
  },
  deliver: {
    carrier: 'RX2GO',
    objectId: undefined,
    type: 9,
    date: moment().format('YYYY-MM-DD'),
    from: '08:00',
    to: '21:00',
    approve: 'face2face',
  },
  pickup: {
    date: moment().format('YYYY-MM-DD'),
    from: '08:00',
    to: '21:00',
  },
  rxs: [],
  documents: [],
  customize: {},
}
let orderSetter = { ...JSON.parse(JSON.stringify(orderDefault)) }

export function useEditableOrder() {
  const state = {}
  const reset = target => () => {
    const object = { ...JSON.parse(JSON.stringify(orderSetter)) }
    _.map(target, (v, k) => {
      if (!(k in object)) {
        target[k] = undefined
        delete target[k]
      }
    })
    Object.assign(target, object)
  }
  reset(state)()

  const order = new Proxy(state, {
    get(target, prop, receiver) {
      if (prop === 'reset') return reset(target)
      return Reflect.get(target, prop, receiver)
    },
    set(target, prop, value, receiver) {
      target[prop] = value
      return true
    },
  })
  return {
    order,
  }
}

const debug = { value: false }
const isLoading = false
export default {
  props: {
    order: {
      type: Object,
      default: () => ({}),
    },
  },
  emits: ['close'],
  data: () => ({
    debug,
    outlined: true,
    print: false,
    saving: false,
    phoneMaska: {
      mask: [
        '+F (###)-###-####',
        '(###)-###-####',
      ],
      tokens: {
        F: {
          pattern: /[1]/,
        },
      },
    },
    phoneMask2: '+1 (###) ###-####',
    phoneRules: [Rules.match(/^\+?1?\s?\(?[\d]{3}\)?-?[\d]{3}-?[\d]{4}$/, 'not valid', false)],
    isRxCopay: false,
    landLineNumber: null,
    // pv lock SCRUM-490
    copayCash: 'yes',
    copayCheck: 'yes',
    validationIsTriggered: false,
  }),
  mounted() {
    this.isRxCopay = Number(this.order.copay) === this.computedRxsCopaySum
    // pv lock SCRUM-490
    if (this.order.uid > 0) {
      this.copayCash = this.order.customize?.order_copay_cash_enabled
      this.copayCheck = this.order.customize?.order_copay_check_enabled
    } else {
      this.copayCash = this.currentUser?.customize?.order_copay_cash_enabled
      this.copayCheck = this.currentUser?.customize?.order_copay_check_enabled
    }
  },
  computed: {
    ...mapGetters(['currentUser', 'facilities']),
    currentFacility() {
      return this.facilities?.[this.currentUser.facility]
    },
    computedDebug: {
      get() { return this.debug.value },
      set(v) { this.debug.value = v },
    },

    currentClient() {
      if (this.clientId !== this.currentUser?.uid) { return this.$store.getters.currentClient(this.clientId) }
      return this.currentUser
    },
    hasUnpaidCopay() {
      return parseFloat(this.order.copay) > 0 && (!this.order.copay_prepaid || this.order.copay_prepaid < 1)
    },
    computedOrderUid: {
      get() { return this.order.uid },
      set(v) { setItem(this.order, 'uid', Number(String(v).trim()) || null) },
    },
    computedPrepaid: {
      get() { return this.order.copay_prepaid },
      set(v) {
        const value = v ? 1 : 0

        if (value) {
          [this.order.copay_in_documents, this.order.copay] = [this.order.copay, '0.00']
        } else {
          [this.order.copay, this.order.copay_in_documents] = [this.order.copay_in_documents, '0.00']
        }

        setItem(this.order, 'copay_prepaid', value)
      },
    },

    validGeo() {
      return this.order.recipient.address.lng && !isNaN(Number(this.order.recipient.address.lng)) && this.order.recipient.address.lat && !isNaN(Number(this.order.recipient.address.lat))
    },

    checkDelivery() {
      if (!this.validGeo) return { }
      return this.$store.getters['Orders/New/checkDelivery']({ lat: this.order.recipient.address.lat, lng: this.order.recipient.address.lng, clientId: this.clientId })
    },
    hasAdditionPhones() {
      return this.order.phones.home !== null || this.order.phones.work !== null
    },
    clientId() {
      return this.order.clientid || this.currentUser.uid
    },
    availableSubTypes() {
      return this.$store.getters['Orders/New/subtypes']
    },
    availableTypes() {
      if (this.computedOrderUid && [12, 14, 24].includes(this.currentOrder?.deliver?.type)) {
        return [
          {
            id: this.currentOrder?.deliver?.type,
            origin_title: this.currentOrder?.deliver?.type_name,
          },
        ]
      }
      return _.filter(this.currentClient?.orderTypes, el => ![12, 14, 24].includes(el.id)) ?? []
    },
    type: {
      get() { return this.order.deliver.type || 9 },
      set(v) { this.order.deliver.type = v || 9 },
    },
    selectedType() {
      let cur
      _.map(this.availableTypes, type => {
        if (type.id === this.type) {
          cur = type
        }
      })
      return cur
    },
    isDelivery() {
      if (this.order.deliver.carrier !== 'RX2GO') return true
      if (!this.validGeo || !(this.type in this.checkDelivery) || !this.selectedInterval) return null
      return this.checkDelivery[this.type]
    },
    defaultOrder() {
      return this.$store.getters['Orders/New/defaultOrder'](this.clientId)
    },
    servicesList() {
      return this.$store.getters['Orders/New/servicesList'](this.clientId)
    },
    selectedService() {
      return this.servicesList[this.type] || {}
    },

    computedDeliverDate: {
      get() { return this.order.deliver.date },
      set(v) { setItem(this.order.deliver, 'date', v) },
    },
    computedFrom: {
      get() { return this.order.deliver.from ? this.convertTime24to12(this.order.deliver.from) : '' },
      set(v) { setItem(this.order.deliver, 'from', v) },
    },
    computedTo: {
      get() { return this.order.deliver.to ? this.convertTime24to12(this.order.deliver.to) : '' },
      set(v) { setItem(this.order.deliver, 'to', v) },
    },

    selectedInterval: {
      get() {
        if (this.computedFrom && this.computedTo) {
          return `${this.computedFrom}-${this.computedTo}`
            .toLowerCase()
            .split(' ')
            .join('')
            .replace(/0(\d):/g, '$1:')
        }
      },
      set(v) {
        if (!v) {
          this.computedFrom = ''
          this.computedTo = ''
          return
        }

        const [from, to] = v
          .replace(/(\d+):/g, '0$1:')
          .replace(/[^|-](\d{2}):/g, '$1:')
          .replace(/:(\d{2})/g, ':$1 ')
          .toUpperCase()
          .split('-')

        this.computedFrom = this.convertTime12to24(from)
        this.computedTo = this.convertTime12to24(to)
      },
    },

    serviceType() {
      return { mode: this.selectedService.mode, min: this.selectedService.min }
    },

    serviceWindows() {
      const dates = {}
      _.map(this.selectedService?.windows ?? [], window => {
        if (!(window.date in dates)) dates[window.date] = []
        dates[window.date].push(window)
      })
      return dates
    },

    selectedServiceWindows() {
      return this.serviceWindows[this.order.deliver.date]
    },

    computedRxsCopaySum() {
      return this.order.rxs?.reduce((sum, { copay }) => sum + Number(copay), 0) || 0
    },
    computedCopay: {
      get() {
        if (this.computedPrepaid) {
          return this.order.copay
        }

        if (this.isRxCopay) {
          this.order.copay = this.rxSum()
        }

        this.isRxCopay = Boolean(this.computedRxsCopaySum)

        return this.order.copay
      },
      set(v) { setItem(this.order, 'copay', v) },
    },
    computedCopayDocuments: {
      get() {
        if (!this.computedPrepaid) {
          return this.order.copay_in_documents
        }

        if (this.isRxCopay) {
          this.order.copay_in_documents = this.rxSum()
        }

        this.isRxCopay = Boolean(this.computedRxsCopaySum)

        return this.order.copay_in_documents
      },
      set(v) { setItem(this.order, 'copay_in_documents', v) },
    },
    currentOrder() {
      return this.$store.getters['Orders/orderDetails'](this.computedOrderUid)
    },
    computedApproveMethod() {
      return this.order?.deliver?.approve
    },
    // pv lock SCRUM-490
    computedOrderCopayPaid() {
      return this?.order?.copay_prepaid
    },
    computedShowCopayType() {
      return !this.computedOrderCopayPaid && this.computedCopayValue > 0
    },
    computedCopayValue() {
      return parseFloat(this?.order?.copay || 0)
    },
  },
  watch: {
    clientId(uid) {
      orderDefault.clientid = uid
    },
    defaultOrder: {
      deep: true,
      handler(order, o) {
        if (!order || this.computedOrderUid) return
        if (order !== o) {
          this.setDefaultOrderData(order)
        }
      },
    },
    computedApproveMethod(method) {
      if (this.landLineNumber) {
        this.checkLandline(method, this.landLineNumber)
      }
    },
  },
  methods: {
    // pv lock SCRUM-490
    validateCopayType(target) {
      if (!target.computedShowCopayType) {
        return true
      }
      const result = target.copayCash === 'yes' || target.copayCheck === 'yes'
      if (!result) {
        this.$toast.error(this.$t('new-order.copay.validation-error'), { timeout: 5000 })
      }
      return result
    },
    rxSum() {
      return this.computedRxsCopaySum.toFixed(2)
    },
    setDefaultOrderData(order) {
      if (Array.isArray(order) && order.length === 0) return
      orderSetter = { ...JSON.parse(JSON.stringify(orderDefault)) }

      if (!order.uid) {
        this.assign(order, orderSetter)
      }
      this.assign(order, this.order)
    },
    assign(object, target) {
      _.map(object, (v, k) => {
        if (k === 'reload') return
        if (typeof v !== 'object' || !(k in target)) {
          setItem(target, k, v)
        } else if (Array.isArray(v)) {
          setItem(target, k, [])
          _.map(v, (vv, i) => {
            if (!target[k].includes(vv)) { target[k].push(vv) }
          })
        } else {
          this.assign(v, target[k])
        }
      })
    },
    isNoContactMethod(method) {
      return ['signlink', 'nosign', 'noask'].includes(method)
    },
    disabledNoContactMethod(method) {
      return this.hasUnpaidCopay && this.isNoContactMethod(method)
    },
    async selectSuggest(suggest, type) {
      if (typeof suggest.addressId !== 'undefined' && (!suggest.latitude || suggest.longitude)) {
        const address = await axios.post('/ajax/PullAddressBook.php', { addressId: suggest.addressId }, { params: { action: 'getRecipientLocation' } }).then(({ data: { address } }) => address)

        suggest.latitude = address.lat
        suggest.longitude = address.lng
        suggest.source = address.source
        suggest.place_id = address.place_id
        suggest.adjusted = address.adjusted
      }

      const needOverride = this.currentUser.customize.override_delivery_type_from_history === 'yes'

      if (!needOverride) {
        this.order.deliver.approve = suggest.delivery_approve_type || this.order.deliver.approve
      }

      if (type === 'name') {
        this.order.phones.call = suggest.recipient_phone || ''
        this.order.phones.home = suggest.recipient_phone2 || null
        this.order.phones.work = suggest.recipient_phone3 || null
        if (suggest.todays_order) {
          setItem(this.order.recipient, 'todays_order', suggest.todays_order)
        }
        if (suggest.recipient_lang) {
          setItem(this.order.recipient, 'lang', suggest.recipient_lang)
        }

        setItem(this.order, 'phones_customize_data', {
          call: (!!suggest?.recipient_phone && !!suggest?.recipient_phone_customize)
            ? suggest?.recipient_phone_customize
            : defaultPhoneCustomize,
          home: (!!suggest?.recipient_phone2 && !!suggest?.recipient_phone2_customize)
            ? suggest?.recipient_phone2_customize
            : defaultPhoneCustomize,
          work: (!!suggest?.recipient_phone3 && !!suggest?.recipient_phone3_customize)
            ? suggest?.recipient_phone3_customize
            : defaultPhoneCustomize,
        })

        this.landLineNumber = null

        const phones = [
          {
            phone: suggest.recipient_phone,
            landline: suggest.recipient_phone_landline,
          },
          {
            phone: suggest.recipient_phone2,
            landline: suggest.recipient_phone2_landline,
          },
          {
            phone: suggest.recipient_phone3,
            landline: suggest.recipient_phone3_landline,
          },
        ]

        if (
          phones
            .filter(suggesData => suggesData.phone?.length).length > 0 // more than one phone
          && phones
            .filter(suggesData => suggesData.landline && suggesData.phone?.length).length > 0 // exist landline
          && phones.filter(suggesData => !suggesData.landline && suggesData.phone?.length).length === 0 // not exist not landline
        ) {
          const landlinePhone = phones
            .find(suggesData => suggesData.landline && suggesData.phone?.length)

          this.landLineNumber = landlinePhone.phone
          this.checkLandline(this.order?.deliver.approve, landlinePhone.phone)
          setItem(this.order.recipient, 'landLineNumber', landlinePhone.phone)
        }
      }

      if (type === 'phone') {
        if (!this.order.recipient.name) {
          this.order.recipient.name = suggest.recipient_name || ''
        }

        if (this.order.recipient.address.address) {
          return
        }
      }

      if (this.order.description) {
        let combinedSugestDescription = this.order.description
        if (suggest.description) {
          combinedSugestDescription = `${suggest.description}, ${this.order.description}`
        }
        suggest.description = combinedSugestDescription
      }

      this.order.type = suggest.typeId || this.order.type
      setItem(this.order, 'description', suggest.description || '')
      setItem(this.order, 'station_instruction', suggest.station_instruction || '')
      this.order.recipient.emails = (suggest.emails && typeof suggest.emails === 'string' ? [suggest.emails] : suggest.emails) || []
      this.order.recipient.address.address = suggest.recipient_address || ''
      this.order.recipient.address.city = suggest.recipient_city || ''
      this.order.recipient.address.zip = suggest.recipient_zip || ''
      this.order.recipient.address.state = suggest.recipient_state || ''
      this.order.recipient.address.apt = suggest.recipient_apt || ''
      this.order.recipient.address.lat = suggest.latitude || ''
      this.order.recipient.address.lng = suggest.longitude || ''
      this.order.recipient.address.place_id = suggest.place_id || ''
      this.order.recipient.address.source = suggest.recipient_source
      this.order.recipient.address.confirm = suggest.recipient_address_confirm || undefined
      this.order.recipient.address.adjusted = suggest.adjusted
      this.order.weight = suggest.weight || ''

      this.order.recipient.address.formatted_address = suggest.formatted_address || null
      if (suggest.auto_select) {
        this.order.recipient.address.auto_select = suggest.auto_select
      }
    },
    resetForm(form) {
      this.order.reset()
      form.resetValidation()
      this.$mitt.emit('formReset')
      // pv lock SCRUM-490
      this.copayCash = this.currentUser?.customize?.order_copay_cash_enabled
      this.copayCheck = this.currentUser?.customize?.order_copay_check_enabled
      this.setDefaultOrderData(this.defaultOrder)
    },

    getTimeZone() {
      return this.currentFacility?.time_zone || 'America/New_York'
    },
    getMoment(date) {
      const time = moment(date).tz(this.getTimeZone())
      time.add(time.isDST() ? 0 : -1, 'hour')
      return time
    },
    convertTime12to24(time12h) {
      if (!time12h) return
      const [time, modifier] = time12h.split(' ')

      let [hours, minutes] = time.split(':')

      minutes = String(minutes)
      if (hours === '12') {
        hours = '00'
      }

      if (modifier === 'PM') {
        hours = parseInt(hours, 10) + 12
      }

      return `0${hours}:${minutes}`.replace(/^0?(\d{2}):/, '$1:')
    },
    convertTime24to12(time24) {
      if (time24.toLowerCase().indexOf('am') >= 0 || time24.toLowerCase().indexOf('pm') >= 0) {
        return time24
      }
      let ts = time24
      const H = +ts.substr(0, 2)
      let h = (H % 12) || 12
      h = (h < 10) ? (`0${h}`) : h // leading 0 at the left for 1 digit hours
      const ampm = H < 12 ? ' AM' : ' PM'
      ts = h + ts.substr(2, 3) + ampm
      return ts
    },

    parseNumber(v) {
      const price = String(v).trim().replace(',', '.')
      return Number.isNaN(parseFloat(price)) ? 0 : parseFloat(price)
    },

    checkValidForm(form) {
      const self = this
      // pv lock SCRUM-490
      this.validationIsTriggered = true
      const showToast = messages => {
        const toastComponent = {
          template: '<span v-html="content"></span>',
          data: () => ({ content: `Unable to save the data: <br>${messages.join('<br>')}` }),
        }

        const opts = [
          'error-id',
          {
            content: toastComponent,
            options: { timeout: 3000, type: TYPE.ERROR, position: POSITION.TOP_CENTER },
          },
          true,
        ]
        self.$toast.update(...opts)
      }

      if (!form.validate() || !this.isDelivery) {
        setTimeout(() => {
          const messages = _.map($('.v-form .v-input.error--text'), e => `${$(e).find('.v-label').text()} - ${$(e).find('.v-messages.error--text').text()}`)
          if (!this.type) {
            messages.push('Please choose delivery type')
          }
          if (!this.isDelivery) {
            messages.push('Sorry. We do not deliver to this addresses')
          }
          showToast(messages)
        }, 200)
        return false
      }
      return true
    },
    async saveProcess(event, target) {
      event.preventDefault()
      const { form } = target.$refs
      if (!this.checkValidForm(form)) return
      // pv lock SCRUM-490
      if (!this.validateCopayType(target)) return

      const skipReason = this.currentFacility?.customize?.disable_order_edit_note === 'yes'
      const reason = await window.getPrompt(
        'Type reason for order changing',
        { title: 'Dispatch note comment', min: 5, type: 'textarea' },
        skipReason,
      )
      if (!reason && !skipReason) {
        return
      }

      this.saving = true
      const isEdit = this.order.uid > 0

      const { order } = this
      // pv lock SCRUM-490
      if (target?.computedShowCopayType) {
        order.customize = {
          ...order.customize,
          ...{
            order_copay_cash_enabled: target.copayCash,
            order_copay_check_enabled: target.copayCheck,
          },
        }
      }

      this.$store.dispatch('Orders/New/saveOrder', { order, reason })
        .then(orderId => {
          if (isEdit) this.$parent.$emit('close')
          window.openOrder(orderId)
          if (!isEdit) this.resetForm(form)
          this.$mitt.emit('recent-orders-table')
        })
        .finally(() => { this.saving = false })
    },
    async createProcess(event, target) {
      event.preventDefault()

      const { form } = target.$refs

      if (!this.checkValidForm(form)) return
      // pv lock SCRUM-490
      if (!this.validateCopayType(target)) return

      if (this.currentUser?.permission?.isRx4Route) {
        const rates = this.$store.getters['Orders/New/rates']
        const currentRx2goRate = rates.find(rate => rate.provider === 'RX2GO_DELIVERY' && rate.object_id === this.order.deliver.type)

        this.order.customize = Object.assign(
          this.order.customize || {},
          {
            Rx4RouteUserUid: this.currentClient.uid,
            ...currentRx2goRate,
          },
        )
      }

      const { order } = this
      // pv lock SCRUM-490
      if (target?.computedShowCopayType) {
        order.customize = {
          ...order.customize,
          ...{
            order_copay_cash_enabled: target.copayCash,
            order_copay_check_enabled: target.copayCheck,
          },
        }
      }

      this.$store.dispatch('Orders/New/createOrder', { order })
        .then(({ created, label, paid }) => {
          const emitFinish = () => {
            if (this.print) {
              window.openLink(label ?? `/print.php?oids=${created}`)
            }
            this.resetForm(form)
            this.$mitt.emit('recent-orders-table')
          }

          if (!['RX2GO', 'RX2GO_DELIVERY'].includes(this.order.deliver.carrier) && !paid) {
            const rates = this.$store.getters['Orders/New/rates']
            const calculatedCarrier = rates.find(rate => rate.provider === this.order.deliver.carrier && rate.service === this.order.deliver.type)

            if (calculatedCarrier) {
              this.$mitt.emit(
                'open-modal',
                'popup-stripe-payment',
                PopupStripePayment,
                {
                  carrier: calculatedCarrier,
                  order: created,
                  user: this.currentUser.uid,
                  callbackSuccess: () => {
                    this.$store.dispatch('Orders/New/createOrderCarrier', {
                      order: {
                        uid: created,
                        rate: calculatedCarrier,
                      },
                    })
                      .then(({ status }) => {
                        if (status) {
                          emitFinish()
                        }
                      })
                      .finally(() => {
                        this.resetForm(form)
                        this.$mitt.emit('recent-orders-table')
                      })
                  },
                  callbackClose: paid => {
                    axios.post(
                      `/delete_orders/${created}`,
                    ).then(() => swal({
                      type: 'error',
                      title: 'Cant create order',
                      html: true,
                      text: 'Order is unpaid and is closed',
                      showCancelButton: false,
                    }))

                    this.resetForm(form)
                    this.$mitt.emit('recent-orders-table')
                  },
                },
              )
            }
          } else {
            emitFinish()
          }
        })
        .finally(() => {
          this.print = false
          this.saving = false
        })

      this.saving = true
    },
    validate() {
      const { form } = this.$refs
      form.validate()
    },
    checkLandline(method, phone) {
      if (phone && method === 'signlink') {
        this.$toast.error(`Phone - ${phone} is landline. Sms notification is impossible`, { timeout: 5000 })
      }
    },
  },
}
