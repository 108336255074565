import _ from 'lodash'

const emitter = {}

export function on(event, callback) {
  if (!(event in emitter)) {
    emitter[event] = []
  }
  emitter[event].push(callback)
}
export function once(event, callback) {
  emitter[event] = [callback]
}

export function off(event) { delete (emitter[event]) }
export function emit(event, ...args) {
  _.map(emitter[event] ?? {}, call => {
    call(...args)
  })
}

const VueMitt = {
  ///* <vue2>
  usable: true,
  // </vue2> */
  install: (app, options) => {
    const setter = app.config?.globalProperties ?? app.prototype
    setter.$mitt = {
      on,
      off,
      emit,
      once,
    }
  },
}

export default VueMitt
