export const formatBarcodeData = barcodeInput => {
  let type = null
  let barcode = null

  if (barcodeInput) {
    const partsBarcode = barcodeInput.split('-')

    if (partsBarcode[0] === 'EQ') {
      type = 'equipment'
      barcode = barcodeInput
    }

    const digitPattern = /^\d{7,}$/
    if (digitPattern.test(partsBarcode[0])) {
      type = 'order'
      barcode = barcodeInput
    }
  }

  return { type, barcode }
}
export const say = text => {
  window.responsiveVoiceRun(text)
}

export const sayNumberFormat = number => number.toString().split('').reverse().join('')
  .match(/.{1,2}/g)
  .join(' ')
  .split('')
  .reverse()
  .join('')

export const play = type => new Promise(resolve => {
  if (type === 'success') {
    playAudio('beep2')
  } else if (type === 'error') {
    playAudio('beep4')
  } else {
    playAudio()
  }

  setTimeout(() => {
    resolve()
  }, 200)
})

export const checkFridgeText = order => {
  if (!order) {
    return false
  }

  if (order.subtype_fridge === 'yes') {
    return 'Order fridge'
  }

  if (order.subtype_freezer === 'yes') {
    return 'Order freezer'
  }

  if (order.subtype_coldnofridge === 'yes') {
    return 'Order cold box'
  }
}

export const getShortHubName = hubName => hubName.split(' ')
  .slice(0, 2)
  .map(word => word[0])
  .join('')
  .toUpperCase()
