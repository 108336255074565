///* <vue2>
import { defineAsyncComponent } from 'vue'
import { createApp } from './plugins/VueControl.js'
// </vue2> */
/* <vue3>
import { createApp, defineAsyncComponent } from 'vue'
// </vue3> */

import '@/helper.js'

import './utils/interceptors.js'

import VueMaska from './plugins/VueMaska.js'
import VueMitt from './plugins/VueMitt.js'
import VueInterval from './plugins/VueInterval.js'
import registryComponents from './plugins/VueComponents.js'
import VueBrowserDetect from './plugins/VueBrowserDetect.js'
import VueClipboard from './plugins/VueClipboard.js'
import VuetifyDatetimePicker from './plugins/VuetifyDatetimePicker.js'
import VueToastification, { VueToastificationOptions } from './plugins/VueToastification.js'

// import VueSentry from './plugins/Sentry.js'

import RxWebSocket from './plugins/RxWebSocket.js'

import i18n from './plugins/i18n.js'
import router from './routes.js'
import store from './store'
import vuetify from './plugins/vuetify.js'

import orderManageMixin from './mixins/order-manage.js'
import orderDetailsMixin from './mixins/order-details.js'

window.orderManageMixin = orderManageMixin
window.orderDetailsMixin = orderDetailsMixin

const App = defineAsyncComponent(() => import(/* webpackChunkName: "main" */ '@/pages/App.vue'))

const app = createApp(App, { name: 'app-js' })
  .use(store)
  .use(router)
  .use(vuetify)
  .use(i18n)
// дальше идут плагины
  .use(VueMitt)
  .use(VueInterval)
  .use(VueMaska)
  .use(VueBrowserDetect)
  // .use(VueSentry)
  .use(VueClipboard)
  .use(VueToastification, VueToastificationOptions)
  .use(VuetifyDatetimePicker)

registryComponents(app)

window.appJs = app.mount('#app-js')
if (document.location.host === 'dashboard.rx2go.ai') {
  document.location = `https://ph.rx2go.ai${document.location.pathname}${document.location.search}${document.location.hash}`
}
// Запускаем работу вебсокета
RxWebSocket.start()

export default window.appJs
