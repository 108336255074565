import { defineAsyncComponent } from 'vue'

export default {
  'Qaqc-queue-tab-apartment': defineAsyncComponent(() => import(/* webpackChunkName: "qaqc" */ '@/pages/Qaqc/Queue/tab/tab-apartment.vue')),
  'Qaqc-queue-tab-delivery': defineAsyncComponent(() => import(/* webpackChunkName: "qaqc" */ '@/pages/Qaqc/Queue/tab/tab-delivery.vue')),
  'Qaqc-queue-tab-name': defineAsyncComponent(() => import(/* webpackChunkName: "qaqc" */ '@/pages/Qaqc/Queue/tab/tab-name.vue')),
  'Qaqc-queue-tab-signature': defineAsyncComponent(() => import(/* webpackChunkName: "qaqc" */ '@/pages/Qaqc/Queue/tab/tab-signature.vue')),
  'Qaqc-queue-tab-geofence': defineAsyncComponent(() => import(/* webpackChunkName: "qaqc" */ '@/pages/Qaqc/Queue/tab/tab-geofence.vue')),
  'Qaqc-queue-tab-method': defineAsyncComponent(() => import(/* webpackChunkName: "qaqc" */ '@/pages/Qaqc/Queue/tab/tab-method.vue')),
  'Qaqc-queue-tab-copay': defineAsyncComponent(() => import(/* webpackChunkName: "qaqc" */ '@/pages/Qaqc/Queue/tab/tab-copay.vue')),
  'Qaqc-queue-tab-fridge': defineAsyncComponent(() => import(/* webpackChunkName: "qaqc" */ '@/pages/Qaqc/Queue/tab/tab-fridge.vue')),
  'Qaqc-queue-tab-freezer': defineAsyncComponent(() => import(/* webpackChunkName: "qaqc" */ '@/pages/Qaqc/Queue/tab/tab-freezer.vue')),
  'Qaqc-queue-tab-id': defineAsyncComponent(() => import(/* webpackChunkName: "qaqc" */ '@/pages/Qaqc/Queue/tab/tab-id.vue')),
  'Qaqc-queue-tab-package': defineAsyncComponent(() => import(/* webpackChunkName: "qaqc" */ '@/pages/Qaqc/Queue/tab/tab-package.vue')),
  'Qaqc-queue-tab-date': defineAsyncComponent(() => import(/* webpackChunkName: "qaqc" */ '@/pages/Qaqc/Queue/tab/tab-date.vue')),
  'Qaqc-queue-tab-instructions': defineAsyncComponent(() => import(/* webpackChunkName: "qaqc" */ '@/pages/Qaqc/Queue/tab/tab-instructions.vue')),
  'Qaqc-queue-tab-attempt_call_pharmacy': defineAsyncComponent(() => import(/* webpackChunkName: "qaqc" */ '@/pages/Qaqc/Queue/tab/tab-attempt_call_pharmacy.vue')),
  'Qaqc-queue-tab-attempt_call_recipient': defineAsyncComponent(() => import(/* webpackChunkName: "qaqc" */ '@/pages/Qaqc/Queue/tab/tab-attempt_call_recipient.vue')),
  'Qaqc-queue-tab-attempt_dispatch_note': defineAsyncComponent(() => import(/* webpackChunkName: "qaqc" */ '@/pages/Qaqc/Queue/tab/tab-attempt_dispatch_note.vue')),
}
