///* <vue2>
import VueToastification, { TYPE } from 'vue-toastification'
// </vue2> */
/* <vue3>
import VueToastification, { TYPE } from 'vue-toastification'
import * as LL from 'vue-toastification'
// </vue3> */

import 'vue-toastification/dist/index.css'

export const VueToastificationOptions = {
  timeout: 5000,
  pauseOnFocusLoss: false,
  hideProgressBar: false,
  draggable: false,
  closeOnClick: false,
  toastDefaults: {
    // ToastOptions object for each type of toast
    [TYPE.ERROR]: {
      timeout: 10000,
    },
    [TYPE.SUCCESS]: {
      timeout: 3000,
    },
  },
}

///* <vue2>
VueToastification.usable = true
export const useToast = () => appJs.$toast
// </vue2> */
/* <vue3>
export const { useToast } = LL
// </vue3> */
export default VueToastification
