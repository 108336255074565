import _ from 'lodash'
import { useStore } from '@/store'

let connection = null
let status = -1
let reconncetTimeout
let requestIterator = 0

const sc = {
  CONNECTING: -1,
  CONNECTED: 1,
  AWAIT: 0,
}
const awatResult = {}

const isConnected = () => status === sc.CONNECTED

const sendMessage = message => new Promise((resolve, reject) => {
  if (!isConnected()) {
    reject()
    return
  }

  if (!('id' in message)) {
    message.id = ++requestIterator
  }
  connection.send(JSON.stringify(message))
  awatResult[message.id] = resolve
})

const append = (...message) => {
  if (window.debug?.['ws-subscribe']) console.log('[WS]', ...message)
}

const subscribes = []
const subscribeProcess = subscribe => sendMessage(
  {
    action: 'subscribe',
    params: subscribe.params,
  },
).then(response => {
  append({ response, subscribe })
  delete (awatResult[response.id])
  response.result.event = 'connect'
  subscribe.uuid = response.result.uuid
  subscribe.onMessage(response, subscribe)
  awatResult[response.result.uuid] = subscribe.onMessage

  return response.result.uuid
})

const subscribe = (params, onMessage) => {
  const subs = {
    uuid: null,
    params,
    onMessage,
    // TODO Если отписаться пока uuid  еще не получег получается какая -то фигня надо с этим поработаеть
    unSubscribe: () => {
      delete (awatResult[subs.uuid])
      sendMessage({
        action: 'unsubscribe',
        params: {
          target: params.target,
          uuid: subs.uuid,
        },
      })
    },
  }
  subscribes.push(subs)
  subscribeProcess(subs)
  return subs
}

const broadcast = (channel, result = {}, event = 'broadcast') => sendMessage({
  action: 'broadcast',
  params: {
    target: 'broadcast',
    event,
    result,
    document: {
      channel,
      ...result,
    },
  },
})
const broadcastChannel = (channel, onMessage, filters = {}) => subscribe({ target: 'broadcast', variables: { channel, ...filters } }, onMessage)

const resubscribeAll = () => {
  _.map(subscribes, subscr => {
    subscribeProcess(subscr)
  })
}

const connect = () => {
  status = sc.CONNECTING
  const token = useStore().getters.authToken
  if (!token) return

  connection = new WebSocket(window.config().wsPublic, `v1-${token}`)
  // , ['v1', token]
  connection.onmessage = event => {
    if (`${event.data}`.toLocaleLowerCase() === 'pong') {
      // Ничего не делаем
    } else if (`${event.data}`.toLocaleLowerCase() === 'ping') {
      connection.send('Pong')
    } else {
      const data = JSON.parse(`${event.data}`)
      // Если есть подписчик и пакет имеет rusult
      if (data.id in awatResult && 'result' in data) {
        awatResult[data.id](data)
      } else {
        append(`<--${event.data}`)
      }
    }
  }
  connection.onopen = () => {
    status = sc.CONNECTED
    append('Connected to WebSocket!')
    resubscribeAll()
  }
  connection.onclose = () => {
    append('Connected Close!')
    status = sc.AWAIT
    connection = null
    _.map(awatResult, (k, v) => {
      delete (awatResult[k])
    })
    reconncetTimeout = setTimeout(connect, 5000)
  }
  connection.onerror = e => {
    append('Error happens', e)
  }
}

// setInterval(() => {
//   if (status === sc.CONNECTED) {
//     const rand = _.random(100000, 999999)
//     sendMessage({
//       action: 'random',
//       params: {
//         rand,
//       },
//     }).then(({ result }) => {
//       // console.log(rand, result)
//     })
//   }
// }, 10000)

export default {
  start() {
    connect()
  },
  broadcastChannel,
  broadcast,
  subscribe,
  isConnected,
}
